div.icon {
  display: inline-block;
}

.icon-button {
  font-size: 150%;
}

.icon-container {
  height: 1.875rem;
  width: 1.375rem;
  overflow: hidden;
  border-radius: $border-radius-lg;
  display: inline-block;
  cursor: default;
  text-align: center;
  position: relative;
}

.icon-container.btn {
  background: $color-background-gray--light;
  width: 1.875rem;
  white-space: nowrap;
}

.icon--with-tooltip {
  position: relative;

  &.with-text {
    display: flex;
    align-items: center;
  }

  .icon-text {
    margin-left: $spacing--tiny;
  }
}

.controls .icon-link,
.controls .icon-link-disabled {
  display: inline-block;
  font-size: 1rem;
  line-height: 0.875rem;
}

.controls .fa-trash-o {
  margin-left: 0.25rem !important; /* stylelint-disable-line declaration-no-important */
}

.fa-primary-color {
  color: $color-brand-blue !important; /* stylelint-disable-line declaration-no-important */
}

.icon--meeting-type-alignment-fix {
  min-width: 1rem;
  height: 0.875rem;
  display: inline-block;
}

.icon-gray {
  color: $color-gray;
}
