@import 'variables-breakpoints';
@import 'variables-colors';
$font-size--xsmall: 0.6rem; // caution: this only to be used in special cases because it is too small
$font-size--small: 0.75rem;
$font-size--base: 14px; /* stylelint-disable-line declaration-property-unit-disallowed-list */
$font-size--1-rem: 1rem; /* ideally, font-size--base would be 1 rem, but that is not the case because... legacy! */
$font-size--medium: 1.125rem;
$font-size--large: 1.25rem;
$font-size--larger: 1.5rem;
$font-size--xlarge: 1.8rem;

$font-size--body: 0.875rem;
$font-size--button: 0.95rem;
$font-size--button-sm: 0.85rem;
$font-size--button-xs: 0.75rem;

$font-weight--light: 300;
$font-weight--medium: 400;
$font-weight--default: 500;
$font-weight--heavy: 600;

$line-height--small: 1.4;
$line-height--default: 1.6;

$spacing--xtiny: 0.15rem;
$spacing--tiny: 0.25rem;
$spacing--small: 0.5rem;
$spacing--default: 0.75rem;
$spacing--medium: 1rem;
$spacing--large: 1.5rem;
$spacing--huge: 2rem;
$spacing--huger: 2.5rem;

$form-group-margin-bottom: 1.25rem;
$form-check-margin-bottom: 1.2rem;
$refinery-right-col-width: 2rem;
$mobile_nav_height: 3.2rem;

$tooltip-background-padding: 1rem;

// Bootstrap Overrides
$font-family-sans-serif: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$brand-primary: $color-brand-blue;
$dropdown-link-hover-bg: $color-list-item-hover;
$dropdown-link-active-color: #fff;

$btn-secondary-color: $color-brand-blue;
$btn-secondary-border: $color-brand-blue;
$btn-secondary-bg: #fff;

$border-radius-xs:       0.1rem;
$border-radius-sm:       0.2rem;
$border-radius:          0.2rem;
$border-radius-lg:       0.25rem;
$border-radius-xlg:      0.5rem;
$border-radius-xxlg:      1.5rem;

$input-border-color: $color-gray--light;

$list-item-border-thickness: 2px; /* stylelint-disable-line declaration-property-unit-disallowed-list */

$state-info-text:                $color-brand-blue-darker;
$state-info-bg:                  $color-background-blue--light !default;
$state-info-border:              $color-brand-blue;

$state-danger-text:              #a94442 !default;

// These all exist to lock the Bootstrap responsiveness to a minimum of 1040px wide.
// Once we're ready to start introducing responsiveness to the app we can selectively remove these.
/* stylelint-disable declaration-property-unit-disallowed-list  */

$container-max-widths: (
  sm: 1100px,
  md: 1120px,
  lg: 1140px,
  xl: 1240px
);

$container-min-width: map_get($container-max-widths, xl);
$width-of-no-disappearing-nav-items: 1500px;

$modal-widths: (
  sm: 300px,
  md: 500px,
  lg: 800px,
  xl: 1200px
);

$form-page-max-width: map_get($container-max-widths, md);

$input-box-shadow: 0 0 3px 0 rgba(#000, 50%);
/* stylelint-enable */
