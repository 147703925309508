:root {
  --theme-alert: hsl(44deg 100% 46%);
  --theme-danger: hsl(7deg 67% 56%);
  --theme-info: hsl(187deg 82% 43%);
  --theme-primary: hsl(187deg 82% 43%);
  --theme-primary-darker: hsl(190deg 92% 34%);
  --theme-primary-highlighted: hsl(187deg 73% 47%);
  --theme-primary-selected: hsl(187deg 70% 51%);
  --theme-success: hsl(113deg 60% 47%);
  --theme-warning: hsl(23deg 100% 57%);
  --theme-future: hsl(214deg 17% 63%);
  --theme-gray-light: hsl(215deg 16% 79%);
  --theme-text-gray: hsl(214deg 17% 63%);
}

body.high-contrast {
  --theme-alert: hsl(44deg 100% 46%);
  --theme-danger: hsl(7deg 67% 50%);
  --theme-info: hsl(187deg 82% 31%);
  --theme-primary: hsl(190deg 90% 31%);
  --theme-primary-darker: hsl(190deg 79% 27%);
  --theme-primary-highlighted: hsl(190deg 79% 27%);
  --theme-primary-selected: hsl(190deg 79% 27%);
  --theme-success: hsl(113deg 60% 33%);
  --theme-warning: hsl(23deg 100% 40%);
  --theme-future: hsl(214deg 17% 45%);
  --theme-gray-light: hsl(215deg 16% 59%);
  --theme-text-gray: hsl(214deg 17% 45%);
}

$color-brand-blue: #14b2c7;
$color-brand-blue-darker: #109cae;
$color-brand-blue--lightest: #2bc6da;

$color-brand-blue--hover: $color-brand-blue-darker;
$color-brand-blue--clicked: $color-brand-blue-darker;
$color-brand-blue--highlight: $color-brand-blue--lightest;

$color-brand-black: #354052;
$color-brand-black--hover: #142239;
$color-brand-black--clicked: #142239;
$color-brand-black--highlight: #4b5971;

$color-link: #087f97;
$color-link-dark: #074150;
$color-text: #354052;
$color-text--gray: #616e89;

$color-dark-blue: #078ca8;
$color-dark-blue--hover: #057288;

$color-gray: #909eb0;
$color-gray--light: #c0c7d1;
$color-gray--lighter: #d5dbe1;
$color-gray--lightest: #f1f3f5;
$color-gray--white-smoke: #eaeaea;

$color-background-gray: #e1e5ea;
$color-background-gray--light: #f6f8fa;
$color-background-gray--lighter: #fafafa;
$color-background-blue--light: #e8f4f9;
$color-background-blue--lighter: #f4fbfc;
$color-background-red--light: #ffe5d4;
$color-background-red--lighter: #fff6f0;

$color-page-background-blue: #f3fafb;
$color-page-background-blue--accent: #e8f4f9;

$color-border: $color-gray--lighter;
$color-border--medium: $color-gray--white-smoke;
$color-border--light: $color-gray--lightest;

$color-list-item-hover: $color-background-gray--light;
$color-list-item-hover--light: #fafafa;

$color-purple: #7e1a7c;
$color-purple--light: #992696;
$color-purple--dark: #6a1268;

$color-white: #fff;
$color-green: #41c130;
$color-navy: #003975;
$color-red: #da5443;
$color-red--darker: #da5443;
$color-red--lightest: $color-background-red--light;
$color-orange: #dcbb41;
$color-bootstrapish-orange: #a5660d;
$color-blue-pastel: #c1e3f0;

$color-proposal-changing-blue: $color-brand-blue;
$color-proposal-changing-blue--background: $color-blue-pastel;
$color-proposal-adding-green: $color-green;
$color-proposal-adding-green--background: #c7f0c1;
$color-proposal-removing-red: $color-red;
$color-proposal-removing-red--background: #f7d8d4;

$color-status-all:     $color-gray--lighter;
$color-status-current: var(--theme-success);
$color-status-future:  var(--theme-future);
$color-status-waiting: var(--theme-warning);
$color-status-done:    var(--theme-info);

$color-proposal-input-changed: #efffff;
$color-input-background: #fff;
$color-icon-button: $color-gray--light;
$color-icon-button--background-hover: darken($color-background-gray--light, 5);
$color-header-icon-button: lighten($color-text, 20);

// need to be the same with bootstrap $input-border-focus
$color-input-highlight: lighten($color-brand-blue, 25%);

$color-tooltip-background: $color-brand-black;

$color-alert-info-button--hover: #cbebf5;
$color-alert-danger-button--hover: #f5cbcb;
