h1, h2, h3, h4, h5, h6 {
  position: relative;
  padding: 0;
  font-weight: 300;
}

h1,
.header--large,
.page__title {
  font-size: $font-size--xlarge;
}

.page__title {
  display: inline-block;
  margin-bottom: 0;
}

h2,
.heading--generic,
.header--medium {
  font-size: $font-size--base;
  font-weight: $font-weight--default;
  text-transform: uppercase;
  color: $color-text--gray;

  .subtitle {
    color: $color-text--gray;
    display: inline-block;
    font-size: 14px;
    margin-left: 5px;
  }
}

.font-size--small {
  font-size: $font-size--small;
}

.font-size--xsmall {
  font-size: $font-size--xsmall;
}

.font-size--base {
  font-size: $font-size--base;
}

.font-size--1-rem {
  font-size: $font-size--1-rem;
}

.font-size--medium {
  font-size: $font-size--medium;
}

.font-size--large {
  font-size: $font-size--large;
}
